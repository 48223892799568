/* eslint-disable react/sort-comp */
import React from 'react';
import PropTypes from 'prop-types';

import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import moment from 'moment';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './HoursList.scss'; // eslint-disable-line css-modules/no-unused-class
import messages from './messages';
import { dateOnlyToString } from '../../core/dateonly';

class ManagedProjectList extends React.Component {
  static contextTypes = {
    client: PropTypes.object.isRequired,
  };
  static propTypes = {
    hoursEntries: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
    filter: PropTypes.shape({
      month: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      project: PropTypes.string,
      type: PropTypes.string,
    }),
    intl: intlShape.isRequired,
  };

  static defaultProps = {
    filter: {},
  };

  render() {
    const { hoursEntries } = this.props;
    let previousDay = null;
    let dayTotal = 0;
    return (
      <div>
        <table className={`table table-striped ${s.hoursListTable}`}>
          <thead>
            <tr>
              <th>
                <FormattedMessage {...messages.time} />
              </th>
              <th>
                <FormattedMessage {...messages.hours} />
              </th>
              <th>
                <FormattedMessage {...messages.type} />
              </th>
              <th>
                <FormattedMessage {...messages.project} />
              </th>
              <th>
                <FormattedMessage {...messages.username} />
              </th>
              <th className={s.hideOnSmall}>
                <FormattedMessage {...messages.category} />
              </th>
              <th className={s.hideOnSmall}>
                <FormattedMessage {...messages.issue} />
              </th>
              <th className={s.hideOnSmall}>
                <FormattedMessage {...messages.description} />
              </th>
            </tr>
          </thead>
          <tbody>
            {hoursEntries.map(entry => {
              const row = [];
              if (previousDay !== entry.date) {
                row.push(
                  <tr key={`${entry.id}__separator`} className={s.separator}>
                    <td colSpan={10}>{dateOnlyToString(entry.date)}</td>
                  </tr>,
                );

                row.unshift(
                  <tr key={`${entry.id}__total`}>
                    <td colSpan={2} style={{ textAlign: 'right' }}>
                      <strong>
                        <FormattedMessage {...messages.dayTotal} />
                      </strong>
                    </td>
                    <td>
                      <strong>{dayTotal.toString()}</strong>
                    </td>
                    <td colSpan={8} />
                  </tr>,
                );
                dayTotal = 0;
              }
              previousDay = entry.date;
              dayTotal += parseFloat(entry.hours, 10);
              row.push(
                <tr key={entry.id} className={s.managedProjectRow}>
                  <td className={s.smallColumn}>
                    {`${moment(entry.timeFrom, 'HH:mm:ss').format(
                      'HH:mm',
                    )} - ${moment(entry.timeFrom, 'HH:mm:ss')
                      .add(entry.hours, 'hours')
                      .format('HH:mm')}`}
                  </td>
                  <td className={s.smallColumn}>{entry.hours}</td>
                  <td className={s.smallColumn}>{entry.type} </td>
                  <td className={s.bigColumn}>{entry.project.name}</td>
                  <td className={s.bigColumn}>{entry.user.username}</td>
                  <td className={`${s.bigColumn} ${s.hideOnSmall}`}>
                    <div
                      title={entry.category && entry.category.name}
                      className={`${s.description} ${s.hideOnSmall}`}
                    >
                      {entry.category && entry.category.name}
                    </div>
                  </td>
                  <td className={`${s.bigColumn} ${s.hideOnSmall}`}>
                    <div title={entry.issue} className={`${s.hideOnSmall}`} />
                    {entry.issue
                      ? `#${entry.issue.ticketId} ${entry.issue.title}`
                      : ''}
                    <div />
                  </td>
                  <td className={`${s.bigColumn} ${s.hideOnSmall}`}>
                    <div
                      title={entry.description}
                      className={`${s.description} ${s.hideOnSmall}`}
                    >
                      {entry.description}
                    </div>
                  </td>
                </tr>,
              );
              return row;
            })}
            {hoursEntries.length > 0 && (
              <tr key={`${hoursEntries[hoursEntries.length - 1].id}__total`}>
                <td colSpan={2} style={{ textAlign: 'right' }}>
                  <strong>
                    <FormattedMessage {...messages.dayTotal} />
                  </strong>
                </td>
                <td>
                  <strong>{dayTotal.toString()}</strong>
                </td>
                <td colSpan={8} />
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  }
}

export default injectIntl(withStyles(s)(ManagedProjectList));
