import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Panel, PanelGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {
  FaFolder,
  FaFolderOpen,
  FaChevronDown,
  FaChevronLeft,
  FaDotCircle,
} from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';

import AuthenticationWrapper from 'components/Auth/AuthenticationWrapper';
import gate from 'components/Auth/gate';
import allRoles from 'core/roles';
import messages from './messages';
import s from './Sidebar.scss';

// only one isMain Nav is allowed and needed
export const navTree = [
  {
    name: messages.dashboard,
    subNavs: [
      {
        name: messages.dashboard,
        path: '/admin/dashboard',
        isMain: true,
        roles: [],
      },
    ],
    openIcon: FaFolderOpen,
    closedIcon: FaFolder,
  },
  {
    name: messages.hours,
    subNavs: [
      {
        name: messages.hours,
        path: '/admin/hours',
        isMain: true,
        roles: [],
      },
    ],
    openIcon: FaFolderOpen,
    closedIcon: FaFolder,
  },
  {
    name: messages.account,
    subNavs: [
      {
        name: messages.account,
        path: '/admin/account',
        isMain: true,
        roles: [],
      },
    ],
    openIcon: FaFolderOpen,
    closedIcon: FaFolder,
  },
  {
    name: messages.users,
    subNavs: [
      {
        name: messages.users,
        path: '/admin/users',
        roles: [allRoles.ADMIN],
        isMain: true,
      },
    ],
    openIcon: FaFolderOpen,
    closedIcon: FaFolder,
  },
  {
    name: messages.shortWork,
    subNavs: [
      {
        name: messages.shortWork,
        path: '/admin/short-work',
        roles: [allRoles.ADMIN],
        isMain: true,
      },
    ],
    openIcon: FaFolderOpen,
    closedIcon: FaFolder,
  },
  {
    name: messages.projects,
    subNavs: [
      {
        name: messages.projects,
        path: '/admin/projects',
        isMain: true,
        roles: [allRoles.ADMIN, allRoles.PROJECT_MANAGER],
      },
    ],
    openIcon: FaFolderOpen,
    closedIcon: FaFolder,
  },
  {
    name: messages.controlling,
    subNavs: [
      {
        name: messages.controlling,
        path: '/admin/controlling',
        roles: [allRoles.ADMIN, allRoles.PROJECT_MANAGER],
        isMain: true,
      },
      {
        name: messages.projectManagement,
        path: '/admin/controlling/projectManagement',
        roles: [allRoles.ADMIN, allRoles.PROJECT_MANAGER],
        isMain: false,
      },
    ],
    openIcon: FaFolderOpen,
    closedIcon: FaFolder,
  },
];

class Sidebar extends React.Component {
  static propTypes = {
    me: PropTypes.shape({
      id: PropTypes.string.isRequired,
      userRoles: PropTypes.array,
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      activeKey: null,
    };

    this.handleSelect = this.handleSelect.bind(this);
  }

  handleSelect(activeKey) {
    this.setState({ activeKey });
  }

  render() {
    const { me } = this.props;
    return (
      <PanelGroup
        className={s.sideNav}
        accordion
        id="sidebar-nav"
        activeKey={this.state.activeKey}
        onSelect={this.handleSelect}
      >
        {navTree.map((nav, index) => {
          if (typeof nav === 'function') {
            // eslint-disable-next-line no-param-reassign
            nav = nav(me);
          }

          const mainNav = nav.subNavs.find(subNav => subNav.isMain);
          const rolesMe = me.roles.map(roles => roles.name);
          const subNavsToShow = nav.subNavs.filter(
            subNav =>
              (!subNav.roles ||
                mainNav.roles.length === 0 ||
                subNav.roles.find(r => rolesMe.includes(r))) &&
              !subNav.isMain,
          );
          const showMainNav =
            (!mainNav.roles ||
              mainNav.roles.length === 0 ||
              gate.isAllowed({ user: me, allowedRoles: mainNav.roles })) &&
            (!nav.filter || nav.filter(this.props.me));
          if (!showMainNav) {
            // only show subNavs where User has access to
            if (subNavsToShow.length === 0) return subNavsToShow;
          }

          const key = `nav_${index}`;
          const Icon =
            this.state.activeKey === index ? nav.openIcon : nav.closedIcon;
          const IconChevron =
            this.state.activeKey === index ? FaChevronDown : FaChevronLeft;
          const navTitle =
            this.state.activeKey === index ||
            this.props.location.pathname === nav.subNavs[0].path
              ? s.navTitleSelected
              : s.navTitle;
          return (
            <Panel className={s.navPanel} key={key} eventKey={index}>
              <Panel.Heading className={s.navHeading}>
                <Panel.Title className={navTitle} toggle>
                  <Link to={showMainNav ? nav.subNavs[0].path : '#'}>
                    <Icon className="fa--prepended" />{' '}
                    <FormattedMessage {...nav.name} />
                  </Link>
                  {nav.subNavs.length > 1 && (
                    <IconChevron className={s.iconRight} />
                  )}
                </Panel.Title>
              </Panel.Heading>
              {nav.subNavs.length > 1 && ( // only render sub panel if it contains more than 1 entry
                <Panel.Body className={s.navBody} collapsible>
                  {nav.subNavs
                    .filter(
                      subNav =>
                        (!subNav.roles ||
                          subNav.roles.find(r => rolesMe.includes(r))) &&
                        !subNav.isMain,
                    )
                    .map((subNav, subIndex) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <div className={s.subNav} key={`subNav_${subIndex}`}>
                        <Link to={subNav.path}>
                          <FaDotCircle className="fa--prepended" />{' '}
                          <FormattedMessage {...subNav.name} />
                        </Link>
                      </div>
                    ))}
                </Panel.Body>
              )}
            </Panel>
          );
        })}
      </PanelGroup>
    );
  }
}

export default AuthenticationWrapper(withStyles(s)(Sidebar));
